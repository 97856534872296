'use strict'

module.exports = {
    OPENED_EXPERIMENTS: 'openedExperiments',
    CSRF_TOKEN: 'csrfToken',
    VIEW_MODE: 'sdkParameters.viewMode',
    RENDERING_ENV: 'sdkParameters.renderingEnv',
    SDK_PARAMETERS: 'sdkParameters',
    MEASURE_PERF: 'measurePerf',
    APP_STUDIO_WIDGETS_STRUCTURE_URL: 'appStudioWidgetsStructureUrl',
    APP_STUDIO_WIDGETS_GHOSTABLE_URL: 'appStudioWidgetsGhostableUrl',
    APP_STUDIO_RESOLVED_GHOST_STRUCTURE: 'appStudioResolvedGhostStructure',
    ADDITIONAL_GHOST_STRUCTURE: 'additionalGhostStructure',
    ADDITIONAL_GHOST_CONTROLLERS: 'additionalGhostControllers'

}
