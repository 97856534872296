'use strict'

const _ = require('lodash')

const APP_WIDGET_TYPE = 'platform.components.AppWidget'
const REPEATER_TYPE = 'wysiwyg.viewer.components.Repeater'
const DELIMITER = '__'
const getUniqueDisplayedId = (originalId, itemId) => originalId + DELIMITER + itemId

function generateGhostCompId(compId, controllerId, itemId) {
    const ghostId = compId + controllerId
    return itemId ? getUniqueDisplayedId(ghostId, itemId) : ghostId
}

function getChildrenForGhost(ghosts, childRoles, controllerId) {
    return _.map(childRoles, role => generateGhostCompId(ghosts[role].id, controllerId))
}

function buildDisplayedOnlyComp({fallbackStructure, comp, compRole, repeaterId, controllerId, parentId, item}) {
    return _.assign({}, comp, {
        id: compRole,
        structureId: generateGhostCompId(comp.id, controllerId, item),
        data: comp.data || {},
        design: comp.design || {},
        type: comp.componentType,
        displayedOnlyComponents: [],
        isDisplayed: true,
        displayedRoot: generateGhostCompId(parentId, controllerId),
        children: _.map(comp.children, child => generateGhostCompId(fallbackStructure[child].id, controllerId, item)),
        parent: generateGhostCompId(parentId, controllerId, parentId === repeaterId ? null : item)
    })
}

function getChildren(parent) {
    return _.map(_.get(parent, 'children', []), childRole => ({childRole, parentId: parent.id}))
}

function createGhostDisplayedOnlyComps({fallbackStructure, repeater, displayedOnlyComps, controllerId}) {
    const repeaterItems = _.get(repeater, 'data.items')

    let repeaterChildren = getChildren(repeater)
    while (repeaterChildren.length > 0) {
        const {childRole, parentId} = repeaterChildren.shift()
        const childComp = _.get(fallbackStructure, childRole)

        const displayedCompsForRole = _.map(repeaterItems, item => buildDisplayedOnlyComp({
            fallbackStructure,
            comp: childComp,
            compRole: childRole,
            repeaterId: repeater.id,
            controllerId,
            parentId,
            item
        }))

        displayedOnlyComps.set(childRole, displayedCompsForRole)

        const children = getChildren(childComp)
        repeaterChildren = repeaterChildren.concat(children)
    }

    return displayedOnlyComps
}

function getConnectionRoles(compIds, connections) {
    return _.mapValues(connections, connectionsList => new Set(_.keys(connectionsList)))
}

function getDataMap(components) {
    return _(components)
        .map('data')
        .compact()
        .omitBy(_.isEmpty)
        .keyBy('id')
        .value()
}

const getCompIdByCompRole = (connectionsMap, controllerId, componentRole) =>
    _.keys(_.get(connectionsMap, [controllerId, componentRole]))[0]

const getGhostParentId = (parentId, controllerId, fallbackStructure, compRole, realConnections) => {
    if (parentId) {
        return generateGhostCompId(parentId, controllerId)
    }

    const parentRole = _.findKey(fallbackStructure, potentialParent => _.includes(potentialParent.children, compRole))
    return getCompIdByCompRole(realConnections, controllerId, parentRole) || null
}

const createGhostComponentsAndConnections = (rawGhosts, parentMap, controllerId, displayedOnlyComps, ghostComps, ghostConnections, fallbackStructure, realConnections) => {
    _.forEach(rawGhosts, (comp, compRole) => {
        const parentId = parentMap[compRole]
        //if this is appWidget we need to use the compId we got because it is used as controllerId as well
        //IF YOU CHANGE THIS you should fix RMI.setPublicAPI in createStartHandler.js - createRemoteModelInterface by finding the compId of the controllerId
        const ghostId = comp.componentType === APP_WIDGET_TYPE ? comp.id : generateGhostCompId(comp.id, controllerId)

        const displayedOnly = displayedOnlyComps.get(compRole)
        const displayedOnlyComponents = _.map(displayedOnly, 'structureId')

        _.forEach(displayedOnly, displayedOnlyComp => {
            const displayedOnlyCompId = displayedOnlyComp.structureId
            delete displayedOnlyComp.structureId
            _.set(ghostComps, displayedOnlyCompId, displayedOnlyComp)
            _.set(ghostConnections, [controllerId, compRole, displayedOnlyCompId], null)
        })

        const ghostComp = _.assign({}, comp, {
            id: compRole,
            data: comp.data || {},
            design: comp.design || {},
            parent: getGhostParentId(parentId, controllerId, fallbackStructure, compRole, realConnections),
            type: comp.componentType,
            children: getChildrenForGhost(rawGhosts, comp.children || [], controllerId),
            displayedOnlyComponents,
            isDisplayed: !displayedOnlyComps.has(compRole),
            displayedRoot: _.get(comp, 'displayedRoot', null)
        })

        _.set(ghostConnections, [controllerId, compRole, ghostId], null)
        _.set(ghostComps, ghostId, ghostComp)
    })
}

const normalizeControllerType = (controllerType, parentControllerType, applicationId) =>
    `${_.includes(parentControllerType, applicationId) ? `${applicationId}-` : ''}${controllerType}`

function getGhostCompAndConnectionsModels(widgetsRawStructures, RMIData) {
    const ghostComps = {}
    const ghostConnections = {}
    const displayedOnlyComps = new Map()
    const parentMap = {}
    const connectionRoles = getConnectionRoles(_.keys(RMIData.components), RMIData.connections, RMIData.components)
    const dataMap = getDataMap(RMIData.components)
    while (!_.isEmpty(connectionRoles)) {
        const controllerId = _.keys(connectionRoles)[0]
        const existingConnections = connectionRoles[controllerId]
        delete connectionRoles[controllerId]
        const applicationId = _.get(dataMap, [controllerId, 'applicationId'])
        const controllerType = _.get(dataMap, [controllerId, 'controllerType'])
        if (!controllerType) {
            continue
        }
        const fallbackStructure = _.get(widgetsRawStructures, [controllerType], {})
        const ghosts = _.reduce(fallbackStructure, (result, comp, compRole) => {
            if (!existingConnections.has(compRole)) {
                const children = _.get(comp, 'children')
                if (!_.isEmpty(children)) {
                    _.forEach(children, child => _.assign(parentMap, {[child]: _.get(comp, 'id')}))
                }

                if (comp.componentType === REPEATER_TYPE) {
                    createGhostDisplayedOnlyComps({
                        fallbackStructure,
                        repeater: comp,
                        displayedOnlyComps,
                        controllerId
                    })
                }

                if (comp.componentType === APP_WIDGET_TYPE) {
                    connectionRoles[comp.id] = new Set()
                    comp.data.controllerType = normalizeControllerType(comp.data.controllerType, controllerType, applicationId)
                    dataMap[comp.id] = comp.data
                }

                return _.assign(result, {[compRole]: comp})
            }
            return result
        }, {})
        createGhostComponentsAndConnections(ghosts, parentMap, controllerId, displayedOnlyComps, ghostComps, ghostConnections, fallbackStructure, RMIData.connections)
    }
    return {ghostComps, ghostConnections}
}

function getAppWidgetRawStructure(widget, appStudioWidgetsUrl, isNewStructure) {
    const applicationId = widget.applicationId
    const widgetId = widget.widgetId
    const ghostStructureModuleUrl = appStudioWidgetsUrl[applicationId][widgetId]
    if (!ghostStructureModuleUrl) {
        return {}
    }

    return fetch(ghostStructureModuleUrl)
        .then(res => res.json())
        .then(structure => ({[widget.controllerType]: isNewStructure ? structure.components : structure}))
}

function getWidgetData(widget) {
    let widgetId
    try {
        widgetId = JSON.parse(widget.data.settings).devCenterWidgetId
    } catch (e) {
        //do nothing
    }
    return {
        controllerType: widget.data.controllerType,
        applicationId: widget.data.applicationId,
        widgetId
    }
}

function getAppWidgetStructureData(appWidgetsComponents, appStudioWidgetsStructureUrl) {
    return _(appWidgetsComponents)
        .map(getWidgetData)
        .filter('widgetId')
        .keyBy('widgetId')
        .map(widget => getAppWidgetRawStructure(widget, appStudioWidgetsStructureUrl, false))
        .value()
}

async function getAllWidgetsRawStructures(appWidgetsComponents, appStudioWidgetsStructureUrl) {
    if (_.isEmpty(appStudioWidgetsStructureUrl)) {
        return {}
    }
    const allGhostStructuresArray = await Promise.all(getAppWidgetStructureData(appWidgetsComponents, appStudioWidgetsStructureUrl))
    return Object.assign({}, ...allGhostStructuresArray)
}

function getGhostStructureResolved(RMIData, appStudioResolvedGhostStructure, appStudioWidgetsGhostStructure) {
    const controllerToGhostStructure = _.reduce(appStudioWidgetsGhostStructure, _.assign)

    return getGhostCompAndConnectionsModels(_.merge(appStudioResolvedGhostStructure, controllerToGhostStructure), RMIData)
}

async function getGhostStructure(RMIData, appStudioWidgetsStructureUrl, appStudioWidgetsGhostStructure) {
    const appWidgetsComponents = _.filter(RMIData.components, ['type', APP_WIDGET_TYPE])
    if (_.isEmpty(appWidgetsComponents)) {
        return {}
    }

    const widgetsRawStructuresByUrl = await getAllWidgetsRawStructures(appWidgetsComponents, appStudioWidgetsStructureUrl)

    const controllerToGhostStructure = _.reduce(appStudioWidgetsGhostStructure, _.assign)

    return getGhostCompAndConnectionsModels(_.merge(widgetsRawStructuresByUrl, controllerToGhostStructure), RMIData)
}

function fetchAppWidgetControllersAndComps(widget, appStudioWidgetsGhostableUrl) {
    const applicationId = widget.applicationId
    const widgetId = widget.widgetId
    const ghostableModuleUrl = appStudioWidgetsGhostableUrl[applicationId][widgetId]
    if (!ghostableModuleUrl) {
        return {}
    }

    return fetch(ghostableModuleUrl)
        .then(res => res.json())
        .then(structure => Object.assign({},
            {
                components: _.mapKeys(structure.widgetsToComponents, (val, key) => `${applicationId}-${key}`),
                controllers: {[widget.applicationId]: structure.controllers}
            }))
}

const isMostOuterController = (controller, appControllersIds) => _.isEmpty(_.map(controller.dependencies, dependency => _.find(appControllersIds, dependency)))

function getControllerData(controller) {
    return {
        controllerType: controller.controllerData.controllerType,
        controllerId: controller.controllerData.id,
        applicationId: controller.controllerData.applicationId,
        widgetId: _.get(controller.controllerData, ['settings', 'devCenterWidgetId']),
        dependencies: controller.dependencies
    }
}

function getAppWidgetControllersAndComp(apps, appStudioWidgetsGhostableUrl) {
    return _.reduce(_.keys(apps), (res, appId) => {
        const app = apps[appId]
        const controllersIds = _.keys(app.controllers)
        return res.concat(_(app.controllers)
            .map(controller => getControllerData(controller))
            .filter('widgetId')
            .filter(controller => isMostOuterController(controller, controllersIds))
            .keyBy('widgetId')
            .map(widget => fetchAppWidgetControllersAndComps(widget, appStudioWidgetsGhostableUrl))
            .value())
    }, [])
}

async function getAllAppsControllersAndComps(apps, appStudioWidgetsGhostableUrl) {
    if (_.isEmpty(appStudioWidgetsGhostableUrl)) {
        return {}
    }
    const allAppsControllersAndComps = await Promise.all(getAppWidgetControllersAndComp(apps, appStudioWidgetsGhostableUrl))
    return _.merge({}, ...allAppsControllersAndComps)
}

const filterNonGhostControllers = (apps, ghostControllersCandidates) => {
    _.forEach(_.keys(apps), appId => {
        const ghostControllerConfigs = ghostControllersCandidates[appId] || {}
        const {controllers: controllerConfigs = {}} = apps[appId]
        _.forEach(ghostControllerConfigs, (ghostControllerConfig, key) => {
            const controller = _.find(controllerConfigs, controllerConfig => controllerConfig.nickname === ghostControllerConfig.nickname)
            if (controller) {
                delete ghostControllerConfigs[key]
            }
        })
    })

    return ghostControllersCandidates
}

async function getGhostable(apps, appStudioWidgetsGhostableUrl, appStudioWidgetsGhostControllers, isExperimentOpen) {
    if (!isExperimentOpen) {
        return {ghostControllers: {}, ghostComponents: {}}
    }
    const appsControllersAndCompsByUrl = await getAllAppsControllersAndComps(apps, appStudioWidgetsGhostableUrl)

    const allControllers = Object.assign({}, appsControllersAndCompsByUrl.controllers, appStudioWidgetsGhostControllers)
    const ghostControllers = filterNonGhostControllers(apps, allControllers)

    const ghostComponents = appsControllersAndCompsByUrl.components || {}

    return {ghostControllers, ghostComponents}
}

module.exports = {
    getGhostStructure,
    getGhostStructureResolved,
    getGhostable
}
