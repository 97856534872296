'use strict'

const platformUtils = require('santa-platform-utils/src/viewer-platform-worker-api')
const _ = require('lodash')
const constants = require('../../constants/constants')
const wixCodeMessageType = require('../../wixCodeMessageTypes')
const {fedops} = require('../../utils/loggingUtils')
const {getGhostStructure, getGhostStructureResolved} = require('../ghostModel')
const {
    MEASURE_PERF,
    OPENED_EXPERIMENTS,
    APP_STUDIO_WIDGETS_STRUCTURE_URL,
    ADDITIONAL_GHOST_STRUCTURE,
    APP_STUDIO_RESOLVED_GHOST_STRUCTURE
} = require('../../constants/store')

const getGhostControllerNickname = controllerId => _.split(controllerId, '_')[1]
const updateGhostCompIdByControllerId = (ghostConnections, compId, controllerId) =>
    _(ghostConnections)
        .mapKeys((val, key) => key === compId ? controllerId : key)
        .mapValues(val1 => _.mapValues(val1, val2 => _.mapKeys(val2, (val3, key3) => key3 === compId ? controllerId : key3))).value()
function createStartHandler({messageService, store}) {
    function createRemoteModelInterface(context, apps, {ghostComps = {}, ghostConnections = {}} = {}) {
        const RMI = new platformUtils.RemoteModelInterface(context)
        let modifiedGhostConnections = ghostConnections

        apps.forEach(app => {
            if (_.get(app, 'module.exports')) {
                RMI.setAppPublicAPI(app.appDefId, app.module.exports)
            }
            Object.keys(app.controllers).forEach(controllerId => {
                if (controllerId.startsWith('ghost-')) {
                    const nickname = getGhostControllerNickname(controllerId)
                    const compId = _.findKey(ghostComps, comp => comp.id === nickname)
                    modifiedGhostConnections = updateGhostCompIdByControllerId(ghostConnections, compId, controllerId)
                    RMI.addComponent(controllerId, ghostComps[compId], true)
                    delete ghostComps[compId]
                    RMI.setPublicAPI(controllerId, app.controllers[controllerId].exports)
                } else {
                    RMI.setPublicAPI(controllerId, app.controllers[controllerId].exports)
                }
            })
        })
        _.forEach(ghostComps, (ghostComp, ghostId) => RMI.addComponent(ghostId, ghostComp, true))
        RMI.addConnections(modifiedGhostConnections)
        return RMI
    }

    function initSdk(messageData, RMI, sdk) {
        sdk.__INTERNAL__.initModel({RMI, componentsHooks: platformUtils.componentsHooks}, messageData.id)
        if (_.isFunction(sdk.__INTERNAL__.addAppStudioGlobalsIfNeeded)) {
            sdk.__INTERNAL__.addAppStudioGlobalsIfNeeded(messageData.id)
        }
        self.ga = sdk.ga

        if (store.getValue(OPENED_EXPERIMENTS).has('remove_dollar_w_from_self')) {
            return
        }
        self.$w = sdk.getSelector(messageData.id)
    }

    function exposePublicAPI({module: appModule = {}, appDefId} = {}, workerId) {
        if (self.pmrpc && appModule.exports && !_.isEmpty(appModule.exports)) {
            self.pmrpc.api.set(`${wixCodeMessageType.PLATFORM_PUBLIC_API_PREFIX}${appDefId}_${workerId}`, appModule.exports)
            messageService.sendMessage({
                type: wixCodeMessageType.REQUEST_API,
                intent: wixCodeMessageType.WIX_CODE_INTENT,
                appDefId,
                workerId
            })
        }
    }

    async function handleStart(messageData, {workerId, getAllApps, env, sdk}) {
        if (!messageData.id) {
            throw new Error('Could not init sdk: `context.id` is missing')
        }
        if (!messageData.context) {
            throw new Error('Could not init sdk: `context.context` is missing')
        }

        const measure = store.getValue(MEASURE_PERF)
        measure.start('start')

        if (!self.pmrpc && self.importScripts) {
            self.importScripts(constants.PM_RPC)
        }

        const allApps = _.isEmpty(messageData.apps) ?
            getAllApps() :
            getAllApps().filter(app => _.includes(messageData.apps, app.appDefId))
        if (env !== 'backend') {
            allApps.forEach(app => exposePublicAPI(app, workerId))
        }
        const allReady = allApps.map(app => {
            app.controllersReady = app.controllersReady && app.controllersReady.then(() =>
                Promise.resolve() //eslint-disable-line promise/no-return-wrap
            )
            return Promise.resolve(app.controllersReady)
        })

        await Promise.all(allReady)
        const ghostModel = store.getValue(OPENED_EXPERIMENTS).has('bv_ghostableUrl') ?
            getGhostStructureResolved(messageData.context, store.getValue(APP_STUDIO_RESOLVED_GHOST_STRUCTURE), store.getValue(ADDITIONAL_GHOST_STRUCTURE)) :
            await getGhostStructure(messageData.context, store.getValue(APP_STUDIO_WIDGETS_STRUCTURE_URL), store.getValue(ADDITIONAL_GHOST_STRUCTURE))

        const RMI = createRemoteModelInterface(messageData.context, getAllApps(), ghostModel)
        initSdk(messageData, RMI, sdk)
        sdk.__INTERNAL__.triggerOnReady(() => {
            messageService.sendWidgetReadyMessage(messageData.id)
            fedops.reportPlatformLoaded()
            if (_.get(self, 'performance.getEntriesByType')) {
                measure.end('start')
                const performanceMetrics = self.performance.getEntriesByType('measure')
                messageService.sendPerformanceMetricsMessage(workerId, JSON.stringify(performanceMetrics))
            }
            _.invoke(sdk, ['__INTERNAL__', 'setShouldClearState'], false)
        })
    }

    return handleStart
}

module.exports = createStartHandler

