'use strict'

const {SlaveBsiManager} = require('@wix/bsi-manager/dist/src/manager-slave')
const getBsiWorkerManager = (commonConfigModule, messageService) => {
	const readOnlyCommonConfig = {
		get: key => commonConfigModule.get()[key],
		subscribe: commonConfigModule.registerToChange.bind(commonConfigModule)
	}
	
	return new SlaveBsiManager()
		.init(
			{
				getCommonConfig: () => readOnlyCommonConfig
			},
			{enableCookie: false}
		)
		.onActivity(() => {
			messageService.sendBsiActivityMessage()
		})
}

module.exports = {
	getBsiWorkerManager
}