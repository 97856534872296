'use strict'

module.exports = class CommonConfigManager {
    constructor() {
        this._subscribers = []
    }
    
    set(newCommonConfig) {
        self.commonConfig = newCommonConfig
        this._subscribers.forEach(subscriber => subscriber(newCommonConfig))   
    }

    registerToChange(handler) {
        this._subscribers.push(handler)
    } 

    get() {
        return self.commonConfig
    }
}
